// 
// ...MP Custom UIKit Variable Overrides...
//

@import 'mp/mp.scss';

//$button-large-padding-horizontal: 20px;
$global-color: black;

$button-primary-background: black;
$button-primary-color: white;
$button-primary-hover-background: white;
$button-primary-hover-color: black;

$button-default-background: transparent;
$button-default-color: black;
$button-default-hover-background: black;
$button-default-hover-color: white;

$button-secondary-background: $mp-orange;
$button-secondary-color: white;
$button-secondary-hover-background: white;
$button-secondary-hover-color: $mp-orange;

@import "~uikit/src/scss/variables-theme";
@import "~uikit/src/scss/mixins-theme";

@mixin hook-button-default(){
    border: 2px solid black;
    font-weight: 500;
    &:hover{
        border: 2px solid black;
        border-color: black !important;
        font-weight: 500;
    }
}

@mixin hook-button-primary(){
    border: 2px solid black;
    font-weight: 500;
}

@mixin hook-button-secondary(){
    font-weight: 500;
    border: 2px solid $mp-orange;
    // &:hover{
    //     border: 2px solid white;
    // }
}

@import "~uikit/src/scss/uikit-theme.scss";
