@mixin mp-color-utilities() {
  @each $color in $mp-colors {
    $k: index($mp-colors, $color);
    $name: nth($mp-color-names, $k);

    .mp-color-#{$name} {
      color: #{$color};
    }
    .mp-background-#{$name} {
      background: #{$color};
    }
  }
}
@include mp-color-utilities();

@mixin mp-button-hide-border() {
    .mp-button-hide-border{
        &:hover{ 
            border: 2px solid white;
        }
    }
}

@include mp-button-hide-border();
