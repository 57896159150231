$global-font-family: 'lato';

$base-heading-font-family: futura-pt, sans-serif !default;
$base-heading-font-weight: 600 !default;

// Colors
//
$mp-beige: #E4DFD8;
$mp-dark-gray: #262626;
$mp-snow-bird-photo: #F8F8F8;
$mp-orange: #CC803C;
$mp-blue: #00699D;
$mp-white: white;
$mp-muted: #D5A96F;

// Breakpoints 
$mp-breakpoint-small: 640px !default;
$mp-breakpoint-medium: 960px !default;
$mp-breakpoint-large: 1200px !default;
$mp-breakpoint-xlarge: 1600px !default;
$mp-breakpoint-xsmall-max: ($mp-breakpoint-small - 1) !default;
$mp-breakpoint-small-max: ($mp-breakpoint-medium - 1) !default;
$mp-breakpoint-medium-max: ($mp-breakpoint-large - 1) !default;
$mp-breakpoint-large-max: ($mp-breakpoint-xlarge - 1) !default;
$mp-sizes: 's', 'm', 'l', 'xl';
$mp-breaks: $mp-breakpoint-small, $mp-breakpoint-medium, $mp-breakpoint-large, $mp-breakpoint-xlarge;
$mp-breaks-max: $mp-breakpoint-xsmall-max, $mp-breakpoint-small-max, $mp-breakpoint-medium-max, $mp-breakpoint-large-max;
$mp-sizes-full: 'small', 'medium', 'large', 'xlarge';

/**
 *
 * =============================================================================
 * MEDIA BREAKPOINTS
 * =============================================================================
 */

$mp-breakpoint-small: 640px !default;
$mp-breakpoint-medium: 960px !default;
$mp-breakpoint-large: 1200px !default;
$mp-breakpoint-xlarge: 1600px !default;
$mp-breakpoint-xsmall-max: ($mp-breakpoint-small - 1) !default;
$mp-breakpoint-small-max: ($mp-breakpoint-medium - 1) !default;
$mp-breakpoint-medium-max: ($mp-breakpoint-large - 1) !default;
$mp-breakpoint-large-max: ($mp-breakpoint-xlarge - 1) !default;
$mp-sizes: 's', 'm', 'l', 'xl';
$mp-breaks: $mp-breakpoint-small, $mp-breakpoint-medium, $mp-breakpoint-large, $mp-breakpoint-xlarge;
$mp-breaks-max: $mp-breakpoint-xsmall-max, $mp-breakpoint-small-max, $mp-breakpoint-medium-max, $mp-breakpoint-large-max;
$mp-sizes-full: 'small', 'medium', 'large', 'xlarge';

$mp-directions: 'top', 'right', 'bottom', 'left';
$mp-spacing: 'margin', 'padding';
$mp-colors: $mp-beige, $mp-dark-gray, $mp-snow-bird-photo, $mp-orange, $mp-blue, $mp-muted, '#000', '#fff';
$mp-color-names: 'beige', 'dark-gray', 'snow-bird-photo', 'orange', 'blue', 'muted', 'black', 'white';

$mp-beige: #E4DFD8; $mp-dark-grey: #262626; $mp-snow-bird-photo: #F8F8F8; $mp-orange: #CC803C; $mp-blue: #00699D; $mp-white: white; $mp-muted: #D5A96F;

$global-link-color: $mp-blue !default;
$base-link-text-decoration: none !default;
$global-link-hover-color: $mp-orange !default;
$base-link-hover-text-decoration: none !default;

$offcanvas-bar-background: $mp-blue !default;
