@import 'mp-variables';
@import 'mp-functions';
@import 'mp-mixins';

// body{
//     font-family: lato, sans-serif;
//     font-weight: 300;
//     font-style: normal;
// }

// h1, h2, h3, h4, h5 { 
//     font-family: futura-pt, sans-serif !important;
//     font-weight: 600;
//     font-style: normal;
// }

.mp-navigation{
    height:100px;
}

.mp-offcanvas{
    color: $mp-blue !important;
}

.mp-nav-logo{
    margin-top: 10px;
    margin-bottom: 10px;
    height:80px;
}

.mp-offcanvas-link{
    color: $mp-beige !important;
}

.mp-latest-issue{
    font-size:14px;
    top:12px;
    left:12px;
    position:absolute;
    padding:8px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    border-radius: 100px;
    background-color:$mp-blue;
    color:white;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
}

.mp-social-icon{
    height:18px;
}

@media only screen and (max-width: 959px) {
    .mp-footer-text{
        font-size: small;
    }
}


.mp-custom-alert { 
    background-color: $mp-orange !important;
    color: white !important; 
    em { 
        color: white; 
    }
}
